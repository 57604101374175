var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-row",
    { attrs: { dense: "" } },
    [
      _c(
        "v-col",
        { attrs: { md: "4", xs: "6" } },
        [
          _c("v-select", {
            attrs: {
              label: "Primary Target Attribute",
              placeholder: "Select a primary target attribute",
              items: _vm.primaryAttributeLookup,
              "item-text": "text",
              "item-value": "id",
              "append-outer-icon": "mdi-arrow-left",
              disabled: "",
              outlined: "",
              dense: "",
            },
            model: {
              value: _vm.item.primaryTargetObjectAttributeId,
              callback: function ($$v) {
                _vm.$set(_vm.item, "primaryTargetObjectAttributeId", $$v)
              },
              expression: "item.primaryTargetObjectAttributeId",
            },
          }),
        ],
        1
      ),
      !_vm.item.isConstantYn
        ? _c(
            "v-col",
            { attrs: { md: "5", xs: "6" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  label: "Foreign Target Attribute",
                  items: _vm.filteredForeignAttributeLookup,
                  rules: [(v) => !!v || "Select a Foreign Target Attribute"],
                  placeholder: "Select a foreign target attribute",
                  "item-text": "text",
                  "item-value": "id",
                  "prepend-icon": "mdi-arrow-right",
                  outlined: "",
                  dense: "",
                  clearable: "",
                },
                model: {
                  value: _vm.item.foreignTargetObjectAttributeId,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "foreignTargetObjectAttributeId", $$v)
                  },
                  expression: "item.foreignTargetObjectAttributeId",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.item.isConstantYn
        ? _c(
            "v-col",
            { attrs: { md: "5", xs: "6" } },
            [
              _c("v-text-field", {
                staticClass: "mb-4",
                attrs: {
                  label: "Constant Value",
                  "prepend-icon": "mdi-arrow-right",
                  rules: [(v) => !!v || "Constant Value is required"],
                  maxlength: 250,
                  outlined: "",
                  counter: "",
                  dense: "",
                  clearable: "",
                  hint: "Enter a Constant Value",
                  "persistent-hint": "",
                },
                model: {
                  value: _vm.item.constantValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.item, "constantValue", $$v)
                  },
                  expression: "item.constantValue",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { md: "2", xs: "6" } },
        [
          _c("v-checkbox", {
            staticClass: "mt-2",
            attrs: { label: "Tick to use a constant value" },
            model: {
              value: _vm.item.isConstantYn,
              callback: function ($$v) {
                _vm.$set(_vm.item, "isConstantYn", $$v)
              },
              expression: "item.isConstantYn",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }