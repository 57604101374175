var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2" },
            [
              _c(
                "h1",
                {
                  staticClass:
                    "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                },
                [_vm._v("Source Objects")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 float-left",
                  attrs: {
                    fab: "",
                    dark: "",
                    "x-small": "",
                    color: "primary",
                    label: "Refresh",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getItemsFiltered()
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        "aria-hidden": "false",
                        "aria-label": "Refresh",
                        dark: "",
                      },
                    },
                    [_vm._v("mdi-refresh")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "item-key": "id",
                  options: _vm.options,
                  "server-items-length": _vm.totalItems,
                  loading: _vm.loading,
                  "loading-text": "Loading... Please wait",
                  "footer-props": {
                    showFirstLastPage: true,
                    itemsPerPageOptions: [20, 50, 100],
                  },
                  "fixed-header": "",
                  dense: "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-col",
                          { attrs: { md: "12", xs: "12" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Source Object",
                                        items:
                                          _vm.filteredLookupData.sourceObjects,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Source Object",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value:
                                          _vm.filterOptions.sourceObjectIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "sourceObjectIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterOptions.sourceObjectIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Requesting Data SME",
                                        items:
                                          _vm.filteredLookupData.dataOwners,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder:
                                          "Search by Requesting Data SME",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value: _vm.filterOptions.dataOwnerIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "dataOwnerIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterOptions.dataOwnerIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Platform",
                                        items: _vm.filteredLookupData.platforms,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Platform",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value: _vm.filterOptions.platformIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "platformIds",
                                            $$v
                                          )
                                        },
                                        expression: "filterOptions.platformIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Data Set",
                                        items: _vm.filteredLookupData.dataSets,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Data Set",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value: _vm.filterOptions.dataSetIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "dataSetIds",
                                            $$v
                                          )
                                        },
                                        expression: "filterOptions.dataSetIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.sourceObjectName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItemClick(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.sourceObjectName) +
                                    " [" +
                                    _vm._s(item.id) +
                                    "]"
                                ),
                              ]
                            ),
                            item.isFSReviewValid == false
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "v-chip--clickable ml-2 float-right",
                                                      attrs: {
                                                        "aria-label":
                                                          "Object FS Review warning",
                                                        "aria-hidden": "false",
                                                        color:
                                                          item.fsReviewRuleCategoryColour,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getFSReviewData(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-alert-circle")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("FS Review Warning")])]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.loadDateText",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.loadDateText))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.platformName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.platformName))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.dataOwnerName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.dataOwnerName))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.dataSetName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(item.responsibleBusAreas) +
                                " - " +
                                _vm._s(item.dataSetName) +
                                " [" +
                                _vm._s(item.dataSetId) +
                                "]"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.sourceObjectTypeName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.sourceObjectTypeName))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "", "max-width": "400" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "aria-label": "Edit",
                                                "aria-hidden": "false",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editItemClick(item)
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-pencil")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Click to Edit/View this item. You must be the data owner or data set owner to Save any changes"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              type: "warning",
                              dense: "",
                              border: "left",
                            },
                          },
                          [_vm._v(" Sorry, no data to display ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              type: "warning",
                              dense: "",
                              border: "left",
                            },
                          },
                          [_vm._v(" Sorry, no results found ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "v-overlay",
                {
                  staticClass: "align-center justify-center",
                  attrs: { value: _vm.overlay },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    scrollable: "",
                    transition: "dialog-bottom-transition",
                  },
                  model: {
                    value: _vm.editDialog,
                    callback: function ($$v) {
                      _vm.editDialog = $$v
                    },
                    expression: "editDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(
                              "Edit - " +
                                _vm._s(_vm.editedItem.sourceObjectName) +
                                " [" +
                                _vm._s(_vm.editedItem.sourceObjectId) +
                                "] " +
                                _vm._s(_vm.editedItem.platformName) +
                                " [" +
                                _vm._s(_vm.editedItem.platformId) +
                                "]"
                            ),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeEditDialog },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "aria-label": "Close",
                                    "aria-hidden": "false",
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "sourceObjectEditForm",
                              model: {
                                value: _vm.editItemIsValid,
                                callback: function ($$v) {
                                  _vm.editItemIsValid = $$v
                                },
                                expression: "editItemIsValid",
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  !_vm.editItemIsValid
                                    ? _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    color: "pink darken-1",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "All data provided must be valid. Any fields marked with "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "red--text",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" are required."),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { staticClass: "pa-2" }, [
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "text-h4 font-weight-bold text-center primary--text pb-4",
                                          },
                                          [_vm._v("SME")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [_c("v-col", { attrs: { cols: "12" } })],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.platforms,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v || "select a platform",
                                              ],
                                              placeholder: "select a platform",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "mandatory field - select the source system providing the data. if not available in drop-down, request addition from template owner.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Platform (SME/UDL) "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.platformId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "platformId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.platformId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Data Classification Detail",
                                              items:
                                                _vm.lookupData
                                                  .dataClassificationDetails,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Select Data Classification Detail",
                                              ],
                                              placeholder:
                                                "Select Data Classification Detail",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Data Classification Detail (SME) "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .dataClassificationDetailId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataClassificationDetailId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataClassificationDetailId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.dataSets,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v || "Select a Data Set",
                                              ],
                                              placeholder: "Select a Data Set",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              hint: "MANDATORY FIELD - Use appropriate 'AREA:{value}' dataset to identify functional area owning data, e.g. AREA:MasterData, AREA-Procurement.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Data Set (SME) "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.dataSetId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataSetId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataSetId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 1000,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Description is required",
                                              ],
                                              counter: "",
                                              required: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Put this into Readable English. This should qualify the SourceObjectName for e.g. a data dictionary or other purpose.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Description (SME) "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.dataOwners,
                                              label:
                                                "Requesting Data SME (SME)",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a Requesting Data SME",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "SME or point of contact for the specific object",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .requestingDataSme,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "requestingDataSme",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.requestingDataSme",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Business Data Owner (SME)",
                                              maxlength: 100,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .businessDataOwner,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "businessDataOwner",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.businessDataOwner",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Data COE Escalation (SME)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "DATA CoE escalation POC, e.g. Lead SME.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .datacoEescalation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "datacoEescalation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.datacoEescalation",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 100,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Extract Frequency is required",
                                              ],
                                              required: "",
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Select refresh frequency based on lowest common denominator of usage requirements.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Extract Frequency (SME) "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem.extractFrequency,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "extractFrequency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.extractFrequency",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData.extractTypes,
                                              label: "Extract Type (SME)",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select an Extract Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: " For more information click the info icon to the right",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "append-outer",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          href: "https://ulatlprod01.atlassian.net/wiki/spaces/DAAP/pages/3359473764/UDL+BDL+Load+Pattern+Combinations+KL",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-information "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem.extractTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "extractTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.extractTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData.objectFormats,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select Object Format",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Object Format (SME) "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem.objectFormatId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "objectFormatId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.objectFormatId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Data Volume Mb (SME)",
                                              maxlength: 9,
                                              type: "number",
                                              rules: _vm.numberRules,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Whole Number input: Examples 2, 10, 100",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.dataVolumeMb,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataVolumeMb",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataVolumeMb",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 50,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Ingestion Data Retention is required",
                                              ],
                                              required: "",
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Rolling BAU data retention for data. C.f. IngestionDataHistory considerations.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Ingestion Data Retention (SME) "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .ingestionDataRetention,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "ingestionDataRetention",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.ingestionDataRetention",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Region Cluster Country (SME)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "eg: LATAM.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .regionClusterCountry,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "regionClusterCountry",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.regionClusterCountry",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "TechPhysicalProdPath (SME)",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Path to production if object already ingested in UDL.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .techPhysicalProdpath,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "techPhysicalProdpath",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.techPhysicalProdpath",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .geoCoverageLevels,
                                              label:
                                                "GeoCoverageLevelLookup (SME)",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a GeoCoverageLevelLookup",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getGeoLookupData(
                                                  _vm.editedItem
                                                    .geoCoverageLevelId
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .geoCoverageLevelId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "geoCoverageLevelId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.geoCoverageLevelId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.geoLookupData,
                                              label: "GeoCoverageValueLookup",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a GeoCoverageValue SME",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .geoCoverageValueCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "geoCoverageValueCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.geoCoverageValueCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData.subFunctionCodes,
                                              label: "SubFunctionLookup",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a SubFunctionLookup",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.subFunctionCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "subFunctionCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.subFunctionCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .businessGroupCoverageCodes,
                                              label:
                                                "BusinessGroupCoverageCode (SME)",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a BusinessGroupCoverageCode",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .businessGroupCoverageCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "businessGroupCoverageCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.businessGroupCoverageCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "SME Notes SOB",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.editedItem.smenotesSob,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "smenotesSob",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.smenotesSob",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Source Multiple File Details (SME)",
                                              maxlength: 200,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sourceMultipleFileDetails,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceMultipleFileDetails",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceMultipleFileDetails",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Source Naming Convention (SME)",
                                              maxlength: 200,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sourceNamingConvention,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceNamingConvention",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceNamingConvention",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Source POC (SME)",
                                              maxlength: 50,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value: _vm.editedItem.sourcePoc,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourcePoc",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourcePoc",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Availability At Source (SME) "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.dataAvailabilityAtSource =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .dataAvailabilityAtSource,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "dataAvailabilityAtSource",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.dataAvailabilityAtSource",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Historical Load Required (SME) "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.historicalLoadRequired =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .historicalLoadRequired,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "historicalLoadRequired",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.historicalLoadRequired",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Historical Data Schema (SME) "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.historicalDataSchema =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .historicalDataSchema,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "historicalDataSchema",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.historicalDataSchema",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "History Load Data Location (SME)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .historyLoadDataLocation,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "historyLoadDataLocation",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.historyLoadDataLocation",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Historical Data Availability At Source (SME) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .historicalDataAvailabilityAtSource,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "historicalDataAvailabilityAtSource",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.historicalDataAvailabilityAtSource",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Schema Drift (SME) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.schemaDriftYn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "schemaDriftYn",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.schemaDriftYn",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { staticClass: "pa-2" }, [
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "text-h4 font-weight-bold text-center primary--text pb-4",
                                          },
                                          [_vm._v("UDL")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [_c("v-col", { attrs: { cols: "12" } })],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Master Pipeline (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of master UDL pipeline",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.masterPipeline,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "masterPipeline",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.masterPipeline",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "History Pipeline Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of history UDL pipeline",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .historyPipelineName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "historyPipelineName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.historyPipelineName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Landed Pipeline Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of landed UDL pipeline",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .landedPipelineName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "landedPipelineName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.landedPipelineName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Processed Pipeline Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of processed UDL pipeline",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .processedPipelineName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "processedPipelineName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.processedPipelineName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "True Technical Source (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "True source name can differ: RDBMS/Blob/FMT/SFTP",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .trueTechnicalSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "trueTechnicalSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.trueTechnicalSource",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Data Object Name In UDL (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of the object in UDL",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .dataObjectNameInUdl,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataObjectNameInUdl",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataObjectNameInUdl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Data Object Name In Source (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Data Object Name in UDL Source",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .dataObjectNameIinSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataObjectNameIinSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataObjectNameIinSource",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Workspace Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.workspaceName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "workspaceName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.workspaceName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Workspace Path (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.workspacePath,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "workspacePath",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.workspacePath",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Notebook Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Mention N/A if no notebook is associated with the object or pipeline",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.notebookName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "notebookName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.notebookName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Linked Service Name Source (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .linkedServiceNameSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "linkedServiceNameSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.linkedServiceNameSource",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Linked Service Name ADB (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .linkedServiceNameAdb,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "linkedServiceNameAdb",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.linkedServiceNameAdb",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "IR Name Source (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.irnameSource,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "irnameSource",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.irnameSource",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Integration Type (UDL)",
                                              items:
                                                _vm.lookupData.integrationTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder: "Integration Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .integrationTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "integrationTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.integrationTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Authentication Method (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .authenticationMethod,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "authenticationMethod",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.authenticationMethod",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Landed File Path (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.landedFilePath,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "landedFilePath",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.landedFilePath",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Processed File Path (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .processedFilePath,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "processedFilePath",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.processedFilePath",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Additional Path (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.additionalPath,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "additionalPath",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.additionalPath",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Number Of Columns (UDL)",
                                              maxlength: 9,
                                              type: "number",
                                              rules: _vm.numberRules,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Number/Integer Value only",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.numberOfColumns,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "numberOfColumns",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.numberOfColumns",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Partitioning ColumnYN (UDL) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .partitioningColumnYn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "partitioningColumnYn",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.partitioningColumnYn",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Load Type (UDL)",
                                              items:
                                                _vm.lookupData.loadUpdateTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder: "Load Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Delta Upsert, Overwrite",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.loadTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "loadTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.loadTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Is Schema Dynamic (UDL) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .isSchemaDynamicYn,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "isSchemaDynamicYn",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.isSchemaDynamicYn",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Partition Column Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of the column where the partition is applied",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .partitionColumnName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "partitionColumnName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.partitionColumnName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Source Incident Queue Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Source Service Now queue name if it exists?",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sourceIncidentQueueName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceIncidentQueueName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceIncidentQueueName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Source SLA (UDL)",
                                              maxlength: 200,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value: _vm.editedItem.sourceSla,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceSla",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceSla",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Trigger Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Name of deployed trigger. This can be common or a single trigger",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.triggerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "triggerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.triggerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Trigger Type (UDL)",
                                              items:
                                                _vm.lookupData.triggerTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder: "Trigger Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.triggerTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "triggerTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.triggerTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Trigger Time (UDL)",
                                              maxlength: 100,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "UTC 24hour timing of scheduled trigger",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.triggerTime,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "triggerTime",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.triggerTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Trigger Day (UDL)",
                                              maxlength: 100,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Day of the week",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.triggerDay,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "triggerDay",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.triggerDay",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Trigger Frequency (UDL)",
                                              maxlength: 100,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Can be triggered multiple times a day/week/month",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.triggerFrequency,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "triggerFrequency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.triggerFrequency",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "ADF Name (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "UDL Azure ADF name where the pipelines are deployed",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.adfname,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "adfname",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.adfname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Updated Phase (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.updatedPhase,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "updatedPhase",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.updatedPhase",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Updated By UDL (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "UDL technical person",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.updatedByUdl,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "updatedByUdl",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.updatedByUdl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "UDL Notes (UDL)",
                                              maxlength: 1000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "To Capture UDL Notes",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.udlnotes,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udlnotes",
                                                  $$v
                                                )
                                              },
                                              expression: "editedItem.udlnotes",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "SASTokenRecipients(UDL)",
                                              maxlength: 500,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sastokenRecipients,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sastokenRecipients",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sastokenRecipients",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Delimiter (UDL)",
                                              maxlength: 10,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.delimiter,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "delimiter",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.delimiter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Escape Character (UDL)",
                                              maxlength: 10,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.escapeCharacter,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "escapeCharacter",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.escapeCharacter",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " IsMultiline (UDL) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.isMultiline,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "isMultiline",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.isMultiline",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "IngestionType (UDL)",
                                              items:
                                                _vm.lookupData.ingestionTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder: "Ingestion Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.ingestionTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "ingestionTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.ingestionTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", { staticClass: "pa-2" }, [
                                        _c(
                                          "h1",
                                          {
                                            staticClass:
                                              "text-h4 font-weight-bold text-center primary--text pb-4",
                                          },
                                          [_vm._v("DevOps")]
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [_c("v-col", { attrs: { cols: "12" } })],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "ArtefactLink (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.artefactLink,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "artefactLink",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.artefactLink",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Trigger Status (DevOps) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.triggerStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "triggerStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.triggerStatus",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream Team DL (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udldownstreamTeamDl,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udldownstreamTeamDl",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udldownstreamTeamDl",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream Team Name (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udldownstreamTeamname,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udldownstreamTeamname",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udldownstreamTeamname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream SPNID (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udldownstreamSpnid,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udldownstreamSpnid",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udldownstreamSpnid",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream ITSG (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udldownstreamItsg,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udldownstreamItsg",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udldownstreamItsg",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream SPN Region (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udlDownstreamSPNRegion,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udlDownstreamSPNRegion",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udlDownstreamSPNRegion",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream SPN Type (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udldownstreamSpntype,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udldownstreamSpntype",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udldownstreamSpntype",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "UDL Downstream Team Manager (DevOps)",
                                              maxlength: 255,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .udldownstreamTeamManager,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "udldownstreamTeamManager",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.udldownstreamTeamManager",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " SASApplicable (DevOps) "
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem.sasapplicable,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "sasapplicable",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.sasapplicable",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": false,
                                            "nudge-right": 40,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "auto",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mb-4",
                                                          attrs: {
                                                            label:
                                                              "SASExpiryDate",
                                                            "prepend-icon":
                                                              "mdi-calendar",
                                                            readonly: "",
                                                            clearable: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.editedItem
                                                                .sasexpirydate,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.editedItem,
                                                                "sasexpirydate",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "editedItem.sasexpirydate",
                                                          },
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.sasExpiryDateMenu,
                                            callback: function ($$v) {
                                              _vm.sasExpiryDateMenu = $$v
                                            },
                                            expression: "sasExpiryDateMenu",
                                          },
                                        },
                                        [
                                          _c("v-date-picker", {
                                            on: {
                                              input: function ($event) {
                                                _vm.sasExpiryDateMenu = false
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.sasexpirydate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sasexpirydate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sasexpirydate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-container",
                            [
                              !_vm.editItemIsValid
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "All data provided must be valid. Any fields marked with "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                " are required. 'Save' button has been disabled until completed."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isBatchLocked
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Saving is currently disabled."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editedItem.isEditable
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "You must be the data owner or data set owner to 'Save' this item"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            block: "",
                                            tile: "",
                                            outlined: "",
                                            color: "secondary",
                                          },
                                          on: { click: _vm.closeEditDialog },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            block: "",
                                            tile: "",
                                            disabled:
                                              !_vm.editItemIsValid ||
                                              _vm.isBatchLocked ||
                                              !_vm.editedItem.isEditable,
                                            color: "secondary",
                                          },
                                          on: { click: _vm.save },
                                        },
                                        [_vm._v("Save")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    scrollable: "",
                    transition: "dialog-bottom-transition",
                  },
                  model: {
                    value: _vm.dialogFSReviewInfo,
                    callback: function ($$v) {
                      _vm.dialogFSReviewInfo = $$v
                    },
                    expression: "dialogFSReviewInfo",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", [_vm._v("mdi-warning")]),
                              _vm._v(_vm._s(_vm.fsDialogTitle)),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeFSReviewInfo },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "aria-label": "Close",
                                    "aria-hidden": "false",
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "text-md-body-1" },
                        [
                          _c("v-simple-table", {
                            attrs: { dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Type"),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Object / Attribute Name"),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Review Information"),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Review Rule Category"),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.fsReviewItems,
                                        function (item) {
                                          return _c(
                                            "tr",
                                            {
                                              key:
                                                item.objectKey +
                                                item.fsreviewReasonId,
                                            },
                                            [
                                              _c("td", [
                                                item.objectTypeCode == "SOA"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Source Object Attribute"
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Source Object"),
                                                    ]),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.objectName) +
                                                    " [" +
                                                    _vm._s(item.objectId) +
                                                    "]"
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.fsreviewReasonDescription
                                                  ) +
                                                    " [" +
                                                    _vm._s(
                                                      item.fsreviewReasonId
                                                    ) +
                                                    "]"
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.fsreviewRuleCategory
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }