
// property names need to match with SourceObjectModel but lowercase first character for camelcase
export class SourceObject {
    sourceObjectId: number;
    sourceObjectName: string;
    requestingDataSme: number;
    requestingDataSmeidValid: boolean;
    consumingProjects: string;
    dataClassificationDetailId: number;
    dataClassification: string;
    dataClassificationId: number
    dataEncryption: string;
    dataSetId: number;
    description: string;
    extractFrequency: string;
    extractType: string;
    extractTypeId: number;
    initialRequestor: string;
    ingestionDataHistory: string;
    ingestionDataRetention: string;
    ingestionFunction: string;
    ingestionProject: string;
    ingestionPriority: string;
    techPhysicalProdpath: string;
    sourcePoc: string;
    objectTypeId: number;
    platformId: number;
    platformName: string;
    smenotesSob: string;
    loadDate: string;
    refreshDate: string;
    pkcheck: boolean;
    jobCompletionCheck: boolean;
    slacheck: boolean;
    isEditable: boolean;
    trueSource: string;
    personalIdentifiableInformationId: number;
    productOwner: string;
    businessDataOwner: string;
    datacoEescalation: string;
    objectFormatId: number;
    dataExtractionGranularity: string;
    dataVolumeMb: string;
    regionClusterCountry: string;
    archivalTypeId: number;
    dataAvailabilityAtSource: boolean;
    historicalDataAvailabilityAtSource: boolean;
    historicalDataSchema: boolean;
    historicalLoadFileLoadCount: string;
    historicalLoadRequired: boolean;
    historyLoadDataLocation: string;
    historyPipelineName: string;
    masterPipeline: string;
    landedPipelineName: string;
    processedPipelineName: string;
    trueTechnicalSource: string;
    dataObjectNameInUdl: string;
    dataObjectNameIinSource: string;
    workspaceName: string;
    workspacePath: string;
    notebookName: string;
    linkedServiceNameAdb: string;
    linkedServiceNameSource: string;
    irnameSource: string;
    integrationTypeId: number;
    authenticationMethod: string;
    landedFilePath: string;
    processedFilePath: string;
    additionalPath: string;
    numberOfColumns: string;
    partitioningColumnYn: boolean;
    loadTypeId: number;
    isSchemaDynamicYn: boolean;
    partitionColumnName: string;
    sourceIncidentQueueName: string;
    sourceDl: string;
    sourceEscalationPoc: string;
    sourceExtractionDetails: string;
    sourceMultipleFileDetails: string;
    sourceNamingConvention: string;
    sourceSla: string;
    previousSourceObjectName: string;
    triggerDay: string;
    triggerFrequency: string;
    triggerName: string;
    triggerTime: string;
    triggerTypeId: number;
    adfname: string;
    updatedPhase: string;
    updatedByUdl: string;
    udlnotes: string;
    artefactLink: string;
    dataRetentionperiodSource: string;
    dataRetentionperiodLanded: string;
    delimiter: string;
    escapeCharacter: string;
    isMultiline: boolean;
    ingestionTypeId: number;
    sastokenRecipients: string;
    sasapplicable: boolean;
    sasexpirydate: string;
    schemaDriftYn: boolean;
    triggerStatus: boolean;
    udldownstreamTeamDl: string;
    udldownstreamTeamname: string;
    udldownstreamSpnid: string;
    udldownstreamItsg: string;
    udldownstreamSpnregion: string;
    udldownstreamSpntype: string;
    udldownstreamTeamManager: string;
    auditIsocountryKey: number;
    geoCoverageLevelId: number;
    geoCoverageValueCode: string;
    businessGroupCoverageCode: string;
    subFunctionCode: string;
    abstractionViewDetails: string;
    templateSOBId: number;
    ucschemaName: string;
}
