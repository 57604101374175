var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "not-authenticated" },
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { attrs: { md: "12", xs: "12" } }, [
                _c("br"),
                _c(
                  "h2",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [_vm._v("Failed to login")]
                ),
                _c("br"),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [
                    _vm._v("Please contact "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "mailto:ia_dl_coe_metastore@Unilever.onmicrosoft.com",
                        },
                      },
                      [_vm._v("IA_DL_COE_MetaStore")]
                    ),
                    _vm._v(
                      " if you are a new user and need to request access."
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [
                    _vm._v(
                      "If you are an existing user this can happen if your access has temporarily expired or if the service is unavailable."
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "text-center", attrs: { color: "primary" } },
                  [
                    _vm._v("You can try returning to the "),
                    _c("a", { attrs: { href: "/" } }, [_vm._v("home page")]),
                    _vm._v(
                      " or attempt a login again by first logging out via the top right dropdown menu."
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }