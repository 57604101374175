var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2" },
            [
              _c(
                "h1",
                {
                  staticClass:
                    "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                },
                [_vm._v("Target Objects")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 float-left",
                  attrs: {
                    fab: "",
                    dark: "",
                    "x-small": "",
                    color: "primary",
                    label: "Refresh",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.getItemsFiltered()
                    },
                  },
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        "aria-hidden": "false",
                        "aria-label": "Refresh",
                        dark: "",
                      },
                    },
                    [_vm._v("mdi-refresh")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pa-2" },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.items,
                  "item-key": "id",
                  options: _vm.options,
                  "server-items-length": _vm.totalItems,
                  loading: _vm.loading,
                  "loading-text": "Loading... Please wait",
                  "footer-props": {
                    showFirstLastPage: true,
                    itemsPerPageOptions: [20, 50, 100],
                  },
                  "fixed-header": "",
                  dense: "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.options = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-col",
                          { attrs: { md: "12", xs: "12" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Target Object",
                                        items:
                                          _vm.filteredLookupData.targetObjects,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Target Object",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value:
                                          _vm.filterOptions.targetObjectIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "targetObjectIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterOptions.targetObjectIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Design Data Owner",
                                        items:
                                          _vm.filteredLookupData.dataOwners,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder:
                                          "Search by Design Data Owner",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value: _vm.filterOptions.dataOwnerIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "dataOwnerIds",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterOptions.dataOwnerIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Platform",
                                        items: _vm.filteredLookupData.platforms,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Platform",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value: _vm.filterOptions.platformIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "platformIds",
                                            $$v
                                          )
                                        },
                                        expression: "filterOptions.platformIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { md: "6", xs: "6" } },
                                  [
                                    _c("v-autocomplete", {
                                      attrs: {
                                        label: "Search by Data Set",
                                        items: _vm.filteredLookupData.dataSets,
                                        "item-text": "text",
                                        "item-value": "id",
                                        placeholder: "Search by Data Set",
                                        outlined: "",
                                        dense: "",
                                        clearable: "",
                                        multiple: "",
                                      },
                                      on: { change: _vm.getItemsByOptions },
                                      model: {
                                        value: _vm.filterOptions.dataSetIds,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterOptions,
                                            "dataSetIds",
                                            $$v
                                          )
                                        },
                                        expression: "filterOptions.dataSetIds",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item.targetObjectName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "span",
                              {
                                staticClass: "v-chip--clickable",
                                on: {
                                  click: function ($event) {
                                    return _vm.editItemClick(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(item.targetObjectName) +
                                    " [" +
                                    _vm._s(item.id) +
                                    "]"
                                ),
                              ]
                            ),
                            item.isFSReviewValid == false
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "v-chip--clickable ml-2 float-right",
                                                      attrs: {
                                                        "aria-label":
                                                          "Object FS Review warning",
                                                        "aria-hidden": "false",
                                                        color:
                                                          item.fsReviewRuleCategoryColour,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getFSReviewData(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v("mdi-alert-circle")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("FS Review Warning")])]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.loadDateText",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.loadDateText))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.platformName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.platformName))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.dataOwnerName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.dataOwnerName))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.dataSetName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(item.responsibleBusAreas) +
                                " - " +
                                _vm._s(item.dataSetName) +
                                " [" +
                                _vm._s(item.dataSetId) +
                                "]"
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.targetObjectTypeName",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "v-chip--clickable",
                            on: {
                              click: function ($event) {
                                return _vm.editItemClick(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.targetObjectTypeName))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.actions",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { left: "", "max-width": "400" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                "aria-label": "Edit",
                                                "aria-hidden": "false",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.editItemClick(item)
                                                },
                                              },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v("mdi-pencil")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "Click to Edit/View this item. You must be the data owner or data set owner to Save any changes"
                              ),
                            ]),
                          ]
                        ),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              type: "warning",
                              dense: "",
                              border: "left",
                            },
                          },
                          [_vm._v(" Sorry, no data to display ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "no-results",
                    fn: function () {
                      return [
                        _c(
                          "v-alert",
                          {
                            staticClass: "mt-4",
                            attrs: {
                              type: "warning",
                              dense: "",
                              border: "left",
                            },
                          },
                          [_vm._v(" Sorry, no results found ")]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c(
                "v-overlay",
                {
                  staticClass: "align-center justify-center",
                  attrs: { value: _vm.overlay },
                },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: "64" },
                  }),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    scrollable: "",
                    transition: "dialog-bottom-transition",
                  },
                  model: {
                    value: _vm.editDialog,
                    callback: function ($$v) {
                      _vm.editDialog = $$v
                    },
                    expression: "editDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c("v-toolbar-title", [
                            _vm._v(
                              "Edit - " +
                                _vm._s(_vm.editedItem.targetObjectName) +
                                " [" +
                                _vm._s(_vm.editedItem.targetObjectId) +
                                "]"
                            ),
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeEditDialog },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "aria-label": "Close",
                                    "aria-hidden": "false",
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "py-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "targetObjectEditForm",
                              model: {
                                value: _vm.editItemIsValid,
                                callback: function ($$v) {
                                  _vm.editItemIsValid = $$v
                                },
                                expression: "editItemIsValid",
                              },
                            },
                            [
                              _c(
                                "v-container",
                                { attrs: { fluid: "" } },
                                [
                                  !_vm.editItemIsValid
                                    ? _c(
                                        "v-row",
                                        { attrs: { "no-gutters": "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c(
                                                "v-alert",
                                                {
                                                  attrs: {
                                                    color: "pink darken-1",
                                                    outlined: "",
                                                    dense: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "All data provided must be valid. Any fields marked with "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "red--text",
                                                    },
                                                    [_vm._v("*")]
                                                  ),
                                                  _vm._v(" are required."),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 100,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Target Object Name is required",
                                              ],
                                              required: "",
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "BDL object name, consistent with DMR & Jira stories. If a hieararchy, each level needs to be entered on a new row.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Target Object Name "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem.targetObjectName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "targetObjectName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.targetObjectName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 100,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Display Name is required",
                                              ],
                                              required: "",
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Often the same as the TargetObjectName but should be in a readable format (don't use camel case here).",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Display Name "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.displayName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "displayName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.displayName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "TOFS Review Rename",
                                              maxlength: 100,
                                              outlined: "",
                                              counter: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Only for use if you want to change the name of an already existing attribute in prod.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.tofsreviewRename,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "tofsreviewRename",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.tofsreviewRename",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.platforms,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v || "Select a platform",
                                              ],
                                              placeholder: "Select a platform",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Source for BDL object. If populated from the UDL, always in first layer of BDL and so put 'BDL-L1(Base)[3]'",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Platform "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.platformId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "platformId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.platformId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Acceptance Criteria",
                                              maxlength: 2000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Fill this out if there are any acceptance criteria for object. Often found in Jira story",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .acceptanceCriteria,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "acceptanceCriteria",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.acceptanceCriteria",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Complexity Estimate",
                                              items:
                                                _vm.lookupData
                                                  .complexityEstimates,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select complexity estimate",
                                              outlined: "",
                                              dense: "",
                                              hint: "Completed by Third party, so leave blank",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .complexityEstimate,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "complexityEstimate",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.complexityEstimate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.dataSets,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v || "Select a Data Set",
                                              ],
                                              placeholder: "Select a Data Set",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              hint: "Function associated with that object. For example if MasterData, select 'AREA-Masterdata'. If another function, contact Template owner to add more options",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Data Set "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.dataSetId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataSetId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataSetId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label:
                                                "Data Classification Detail",
                                              items:
                                                _vm.lookupData
                                                  .dataClassificationDetails,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Select a Data Classification Detail",
                                              ],
                                              placeholder:
                                                "Select Data Classification Detail",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Data Classification Detail "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .dataClassificationDetailId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataClassificationDetailId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataClassificationDetailId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Data Encryption",
                                              items: _vm.yesNoList,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select Data Encryption",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Does the data need to be encrypted?",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.dataEncryption,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataEncryption",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataEncryption",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.lookupData.dataOwners,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Select a Design Data Owner",
                                              ],
                                              placeholder:
                                                "Select a Design Data Owner",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              hint: "Who is responsible for that specific object or attribute, usually who is entering the data.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Design Data Owner "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.dataOwnerId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataOwnerId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataOwnerId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Previous Data Owner",
                                              items: _vm.lookupData.dataOwners,
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a Previous Data Owner",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "Object Owner at the time of Ingestion.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .previousDataOwnerId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "previousDataOwnerId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.previousDataOwnerId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 250,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Business Data Owner Name "
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .businessDataOwnerName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "businessDataOwnerName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.businessDataOwnerName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 1000,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Description is required",
                                              ],
                                              counter: "",
                                              required: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Description: Business Description. What would be shown to end users.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Description "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.description,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.description",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 10000,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Data Refresh Frequency is required",
                                              ],
                                              counter: "",
                                              required: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "How often the data is refreshed.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Data Refresh Frequency "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .dataRefreshFrequency,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataRefreshFrequency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataRefreshFrequency",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 10000,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Data History BAU is required",
                                              ],
                                              counter: "",
                                              required: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Indicate how long should the data be kept in BDL once in BAU, e.g. 7 Years",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Data History BAU "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem.dataHistoryBau,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataHistoryBau",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataHistoryBau",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 10000,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Data History Initial is required",
                                              ],
                                              counter: "",
                                              required: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Indicate how much History data should be loaded at initial go-live. e.g. 7 Years or n/a",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Data History Initial "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .dataHistoryInitial,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "dataHistoryInitial",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.dataHistoryInitial",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .targetObjectTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Select a Target Object Type",
                                              ],
                                              placeholder:
                                                "Select a Target Object Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "REQUIRED FOR FS REVIEW - Dimension or Fact Object. Define here.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Target Object Type "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .targetObjectTypeId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "targetObjectTypeId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.targetObjectTypeId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Source Join Conditions",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Source Join condition need to be provided in case of standard join condition to a join table. For example Transaction data table has FK to Material dimension. Then join condition between TD table and MARA need to be provided. Source join condition should be provided in special cases such when loading PurchaseDocLine a join might be required to PurchaseDocHeader table. In that case join condition between EKKO and EKPO needs to be provided.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .sourceJoinConditions,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "sourceJoinConditions",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.sourceJoinConditions",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Schedule Dependencies",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: 'Outline any dependencies, if none put "Undefined".',
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .scheduleDependencies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "scheduleDependencies",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.scheduleDependencies",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "SME Notes TOB",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Notes for the SME of that object (Dataowner)",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value: _vm.editedItem.smenotesTob,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "smenotesTob",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.smenotesTob",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.updateTypes,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Select an Update Type",
                                              ],
                                              placeholder:
                                                "Select an Update Type",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "For more information click the info icon to the right",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(" Update Type "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                              {
                                                key: "append-outer",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          href: "https://ulatlprod01.atlassian.net/wiki/spaces/DAAP/pages/3359473764/UDL+BDL+Load+Pattern+Combinations+KL",
                                                          target: "_blank",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-information "
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value: _vm.editedItem.updateType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "updateType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.updateType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Detailed Instructions",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Any extra descriptions or instructions put here.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .detailedInstructions,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "detailedInstructions",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.detailedInstructions",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .techDeletePatterns,
                                              "item-text": "text",
                                              "item-value": "id",
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Tech Delete Pattern is required",
                                              ],
                                              placeholder:
                                                "Select a Tech Delete Pattern",
                                              required: "",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "REQUIRED FOR FS REVIEW - TechDeletePattern: Please use one of the below standard values: 1) Mark as inactive if a row marked as inactive in 2) Never mark as inactive 3) Full Refresh 4) Rebuild row if a TOA in TO matches inactive row from.",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Tech Delete Pattern "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .techDeletePatternId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "techDeletePatternId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.techDeletePatternId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              maxlength: 10000,
                                              rules: [
                                                (v) =>
                                                  !!v ||
                                                  "Tech Delete Dependencies is required",
                                              ],
                                              counter: "",
                                              required: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "REQUIRED FOR FS REVIEW - TechDeleteDependencies: Please use the convention such as 'SOB:ObjectName | TOB:ObjectName'. Use the pipe '|' delimeter to separate objectnames. For example: SOB:MARD|TOB:DeliveryLine'",
                                              "persistent-hint": "",
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "label",
                                                fn: function () {
                                                  return [
                                                    _vm._v(
                                                      " Tech Delete Dependencies "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "red--text",
                                                      },
                                                      [_vm._v("*")]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .techDeleteDependencies,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "techDeleteDependencies",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.techDeleteDependencies",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Object Hard Dependency",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "REQUIRED FOR FS REVIEW - SME should copy the object from the column ObjectCalcDependency into this column depending on if it is hard or soft dependency. Please ensure to retain the data format when entering the value.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .objectHardDependency,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "objectHardDependency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.objectHardDependency",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Object Soft Dependency",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "SME should copy the object from the column ObjectCalcDependency into this column depending on if it is hard or soft dependency. Please ensure to retain the data format when entering the value.",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .objectSoftDependency,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "objectSoftDependency",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.objectSoftDependency",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "Object DQ Requirement",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Free text field. Initially populated based on data captured using DQ Scenario Template in the format as 'DQ:<DQScenarioId>:<DQScenarioName>:<DQScenarioDescription>'",
                                              "peristent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .objectDqrequirement,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "objectDqrequirement",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.objectDqrequirement",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .geoCoverageLevels,
                                              label: "GeoCoverageLevelLookup",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a GeoCoverageLevelLookup",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.getGeoLookupData(
                                                  _vm.editedItem
                                                    .geoCoverageLevelId
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .geoCoverageLevelId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "geoCoverageLevelId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.geoCoverageLevelId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items: _vm.geoLookupData,
                                              label: "GeoCoverageValueLookup",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a GeoCoverageValue SME",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .geoCoverageValueCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "geoCoverageValueCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.geoCoverageValueCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData.subFunctionCodes,
                                              label: "SubFunctionLookup",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a SubFunctionLookup",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem.subFunctionCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "subFunctionCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.subFunctionCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-autocomplete", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              items:
                                                _vm.lookupData
                                                  .businessGroupCoverageCodes,
                                              label:
                                                "BusinessGroupCoverageCode",
                                              "item-text": "text",
                                              "item-value": "id",
                                              placeholder:
                                                "Select a BusinessGroupCoverageCode",
                                              outlined: "",
                                              dense: "",
                                              clearable: "",
                                              hint: "",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .businessGroupCoverageCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "businessGroupCoverageCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.businessGroupCoverageCode",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("v-textarea", {
                                            staticClass: "mb-4",
                                            attrs: {
                                              label: "UAT Filter Attribute",
                                              maxlength: 10000,
                                              counter: "",
                                              outlined: "",
                                              "auto-grow": "",
                                              clearable: "",
                                              dense: "",
                                              hint: "Specify TOA to be used as part STATIC UAT Environment data load. Please use convention as: TOB1.TOA1|TOB1.TOA2",
                                              "persistent-hint": "",
                                            },
                                            model: {
                                              value:
                                                _vm.editedItem
                                                  .uatfilterAttribute,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.editedItem,
                                                  "uatfilterAttribute",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "editedItem.uatfilterAttribute",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Quality Primary Key Check? "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.pkcheck =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value: _vm.editedItem.pkcheck,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "pkcheck",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.pkcheck",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Quality Job Completion Check? "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.jobCompletionCheck =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value:
                                                  _vm.editedItem
                                                    .jobCompletionCheck,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "jobCompletionCheck",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.jobCompletionCheck",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-radio-group",
                                            {
                                              staticClass: "mb-4",
                                              attrs: { dense: "" },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _vm._v(
                                                        " Data Quality SLA Check? "
                                                      ),
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.editedItem.slacheck =
                                                                null
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("mdi-close")]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ]),
                                              model: {
                                                value: _vm.editedItem.slacheck,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.editedItem,
                                                    "slacheck",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "editedItem.slacheck",
                                              },
                                            },
                                            [
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "Yes",
                                                  value: true,
                                                },
                                              }),
                                              _c("v-radio", {
                                                attrs: {
                                                  label: "No",
                                                  value: false,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-container",
                            [
                              !_vm.editItemIsValid
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "All data provided must be valid. Any fields marked with "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "red--text" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                " are required. 'Save' button has been disabled until completed."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isBatchLocked
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " There is a scheduled job in progress that must be completed. Please wait and try refreshing later. Saving is currently disabled."
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.editedItem.isEditable
                                ? _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "v-alert",
                                            {
                                              attrs: {
                                                color: "pink darken-1",
                                                outlined: "",
                                                dense: "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "You must be the data owner or data set owner to 'Save' this item"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            block: "",
                                            tile: "",
                                            outlined: "",
                                            color: "secondary",
                                          },
                                          on: { click: _vm.closeEditDialog },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            block: "",
                                            tile: "",
                                            disabled:
                                              !_vm.editItemIsValid ||
                                              _vm.isBatchLocked ||
                                              !_vm.editedItem.isEditable,
                                            color: "secondary",
                                          },
                                          on: { click: _vm.save },
                                        },
                                        [_vm._v("Save")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    fullscreen: "",
                    scrollable: "",
                    transition: "dialog-bottom-transition",
                  },
                  model: {
                    value: _vm.dialogFSReviewInfo,
                    callback: function ($$v) {
                      _vm.dialogFSReviewInfo = $$v
                    },
                    expression: "dialogFSReviewInfo",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "mb-5 flex-grow-0",
                          attrs: { dark: "", color: "primary" },
                        },
                        [
                          _c(
                            "v-toolbar-title",
                            [
                              _c("v-icon", [_vm._v("mdi-warning")]),
                              _vm._v(_vm._s(_vm.fsDialogTitle)),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeFSReviewInfo },
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "aria-label": "Close",
                                    "aria-hidden": "false",
                                  },
                                },
                                [_vm._v("mdi-close")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "text-md-body-1" },
                        [
                          _c("v-simple-table", {
                            attrs: { dense: "" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    _c("thead", [
                                      _c("tr", [
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Type"),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Object / Attribute Name"),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Review Information"),
                                        ]),
                                        _c("th", { staticClass: "text-left" }, [
                                          _vm._v("Review Rule Category"),
                                        ]),
                                      ]),
                                    ]),
                                    _c(
                                      "tbody",
                                      _vm._l(
                                        _vm.fsReviewItems,
                                        function (item) {
                                          return _c(
                                            "tr",
                                            {
                                              key:
                                                item.objectKey +
                                                item.fsreviewReasonId,
                                            },
                                            [
                                              _c("td", [
                                                item.objectTypeCode == "TOA"
                                                  ? _c("span", [
                                                      _vm._v(
                                                        "Target Object Attribute"
                                                      ),
                                                    ])
                                                  : _c("span", [
                                                      _vm._v("Target Object"),
                                                    ]),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(item.objectName) +
                                                    " [" +
                                                    _vm._s(item.objectId) +
                                                    "]"
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.fsreviewReasonDescription
                                                  ) +
                                                    " [" +
                                                    _vm._s(
                                                      item.fsreviewReasonId
                                                    ) +
                                                    "]"
                                                ),
                                              ]),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    item.fsreviewRuleCategory
                                                  )
                                                ),
                                              ]),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }