import { DropdownOptionIntegerId } from '@/models/DropdownOptionIntegerId';

export class TargetObjectAttributeLookupData {
    targetObjects: Array<DropdownOptionIntegerId>;
    dataOwners: Array<DropdownOptionIntegerId>;
    dataTypes: Array<DropdownOptionIntegerId>;
    measureGroups: Array<DropdownOptionIntegerId>;
    kpiAreas: Array<DropdownOptionIntegerId>;
    dataClassificationDetails: Array<DropdownOptionIntegerId>;
    glossaryTerms: Array<DropdownOptionIntegerId>;
}
