var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm.isRelationshipStatusChanged
      ? _c(
          "div",
          [
            _c(
              "v-btn",
              {
                staticClass: "float-save-button",
                attrs: { color: "primary" },
                on: { click: _vm.openRelationsCertifyDialog },
              },
              [_vm._v(" Save Changes ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c("div", { staticClass: "main" }, [
      _c(
        "div",
        { staticClass: "object-relations" },
        [
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-h4 font-weight-bold text-left primary--text pb-4 float-left",
                        },
                        [_vm._v("Object Relations")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4 float-left",
                          attrs: {
                            fab: "",
                            dark: "",
                            "x-small": "",
                            color: "primary",
                            label: "Refresh",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.getItemsFiltered()
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                "aria-hidden": "false",
                                "aria-label": "Refresh",
                                dark: "",
                              },
                            },
                            [_vm._v("mdi-refresh")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-2 float-right",
                          attrs: { color: "primary", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.createItem()
                            },
                          },
                        },
                        [_vm._v("Create")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-2" },
                    [
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.headers,
                          items: _vm.items,
                          "item-key": "id",
                          options: _vm.options,
                          "server-items-length": _vm.totalItems,
                          loading: _vm.loading,
                          "loading-text": "Loading... Please wait",
                          "footer-props": {
                            showFirstLastPage: true,
                            itemsPerPageOptions: [20, 50, 100],
                          },
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-col",
                                  { attrs: { md: "12", xs: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6", xs: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                label:
                                                  "Search by Relation Type",
                                                items:
                                                  _vm.filteredLookupData
                                                    .relationTypes,
                                                "item-text": "text",
                                                "item-value": "id",
                                                placeholder:
                                                  "Search by Relation Type",
                                                rules: [
                                                  (v) =>
                                                    !!v ||
                                                    "Select a Relation Type",
                                                ],
                                                required: "",
                                                outlined: "",
                                                dense: "",
                                              },
                                              on: {
                                                change: _vm.getItemsByOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.filterOptions
                                                    .relationTypeIds,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filterOptions,
                                                    "relationTypeIds",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterOptions.relationTypeIds",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6", xs: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                label:
                                                  "Search by Foreign Object Owner",
                                                items:
                                                  _vm.filteredLookupData
                                                    .relationshipOwners,
                                                "item-text": "text",
                                                "item-value": "id",
                                                placeholder:
                                                  "Search by Relation Owner",
                                                outlined: "",
                                                dense: "",
                                                clearable: "",
                                                multiple: "",
                                              },
                                              on: {
                                                change: _vm.getItemsByOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.filterOptions
                                                    .relationshipOwnerIds,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filterOptions,
                                                    "relationshipOwnerIds",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterOptions.relationshipOwnerIds",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6", xs: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                label:
                                                  "Search by Primary Object",
                                                items:
                                                  _vm.filteredLookupData
                                                    .primaryTargetObjects,
                                                "item-text": "text",
                                                "item-value": "id",
                                                placeholder:
                                                  "Search by Primary Object",
                                                outlined: "",
                                                dense: "",
                                                clearable: "",
                                                multiple: "",
                                              },
                                              on: {
                                                change: _vm.getItemsByOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.filterOptions
                                                    .primaryTargetObjectIds,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filterOptions,
                                                    "primaryTargetObjectIds",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterOptions.primaryTargetObjectIds",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          { attrs: { md: "6", xs: "6" } },
                                          [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                label:
                                                  "Search by Foreign Object",
                                                items:
                                                  _vm.filteredLookupData
                                                    .foreignTargetObjects,
                                                "item-text": "text",
                                                "item-value": "id",
                                                placeholder:
                                                  "Search by Foreign Object",
                                                outlined: "",
                                                dense: "",
                                                clearable: "",
                                                multiple: "",
                                              },
                                              on: {
                                                change: _vm.getItemsByOptions,
                                              },
                                              model: {
                                                value:
                                                  _vm.filterOptions
                                                    .foreignTargetObjectIds,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filterOptions,
                                                    "foreignTargetObjectIds",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filterOptions.foreignTargetObjectIds",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.select",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "div",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "div",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      disabled:
                                                        !item.allowUserCertification,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.changeCertifiedRelationship(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        item.relationCertifiedFlag,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "relationCertifiedFlag",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.relationCertifiedFlag",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    item.allowUserCertification
                                      ? _c("span", [_vm._v("Select")])
                                      : _c("span", [
                                          _vm._v(
                                            " You must be the Design Data Owner / Data Set Owner of the foreign object to Certify/Decertify this relationship "
                                          ),
                                        ]),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "header.select",
                            fn: function () {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                _vm._b({}, "div", attrs, false),
                                                on
                                              ),
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    active:
                                                      _vm.selectedRelationships
                                                        .length ===
                                                      _vm.currentTotalItems,
                                                    disabled:
                                                      _vm.selectedRelationships
                                                        .length <= 0,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeCertificationPermissionForAll()
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "label",
                                                        fn: function () {
                                                          return [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-start text-uppercase text-subtitle-1 primary--text font-weight-bold",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Certify"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "Certify/Decertify All relationships displayed in this page"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.objectRelationName",
                            fn: function ({ item }) {
                              return [
                                item.isEditable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "v-chip--clickable",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.objectRelationName) +
                                            " [" +
                                            _vm._s(item.id) +
                                            "]"
                                        ),
                                      ]
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(item.objectRelationName) +
                                          " [" +
                                          _vm._s(item.id) +
                                          "]"
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "item.relationTypeName",
                            fn: function ({ item }) {
                              return [
                                item.isEditable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "v-chip--clickable",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.relationTypeName))]
                                    )
                                  : _c("div", [
                                      _vm._v(_vm._s(item.relationTypeName)),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "item.primaryTargetObjectName",
                            fn: function ({ item }) {
                              return [
                                item.isEditable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "v-chip--clickable",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.primaryTargetObjectName) +
                                            " [" +
                                            _vm._s(item.primaryObjectKey) +
                                            "] " +
                                            _vm._s(
                                              item.primaryTargetObjectPlatformName
                                            ) +
                                            " [" +
                                            _vm._s(
                                              item.primaryTargetObjectPlatformId
                                            ) +
                                            "]"
                                        ),
                                      ]
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(item.primaryTargetObjectName) +
                                          " [" +
                                          _vm._s(item.primaryTargetObjectId) +
                                          "] " +
                                          _vm._s(
                                            item.primaryTargetObjectPlatformName
                                          ) +
                                          " [" +
                                          _vm._s(
                                            item.primaryTargetObjectPlatformId
                                          ) +
                                          "]"
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "item.foreignTargetObjectName",
                            fn: function ({ item }) {
                              return [
                                item.isEditable
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "v-chip--clickable",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(item)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.foreignTargetObjectName) +
                                            " [" +
                                            _vm._s(item.foreignObjectKey) +
                                            "] " +
                                            _vm._s(
                                              item.foreignTargetObjectPlatformName
                                            ) +
                                            " [" +
                                            _vm._s(
                                              item.foreignTargetObjectPlatformId
                                            ) +
                                            "]"
                                        ),
                                      ]
                                    )
                                  : _c("div", [
                                      _vm._v(
                                        _vm._s(item.foreignTargetObjectName) +
                                          " [" +
                                          _vm._s(item.foreignTargetObjectId) +
                                          "] " +
                                          _vm._s(
                                            item.foreignTargetObjectPlatformName
                                          ) +
                                          " [" +
                                          _vm._s(
                                            item.foreignTargetObjectPlatformId
                                          ) +
                                          "]"
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "item.actions",
                            fn: function ({ item }) {
                              return [
                                item.isEditable
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          "aria-label": "Edit",
                                          "aria-hidden": "false",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-pencil")]
                                    )
                                  : _vm._e(),
                                !item.isEditable
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { left: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "aria-label":
                                                              "Edit",
                                                            "aria-hidden":
                                                              "false",
                                                            color: "grey",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_vm._v("mdi-pencil")]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "You must be the data owner or data set owner of the foreign attribute to edit this item"
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                item.isEditable
                                  ? _c(
                                      "v-icon",
                                      {
                                        staticClass: "mr-2",
                                        attrs: {
                                          "aria-label": "Delete",
                                          "aria-hidden": "false",
                                          color: "primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteItem(item)
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-trash-can-outline")]
                                    )
                                  : _vm._e(),
                                !item.isEditable
                                  ? _c(
                                      "v-tooltip",
                                      {
                                        attrs: { left: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            "aria-label":
                                                              "Delete",
                                                            "aria-hidden":
                                                              "false",
                                                            color: "grey",
                                                          },
                                                        },
                                                        "v-icon",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "mdi-trash-can-outline"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "You must be the data owner or data set owner of the foreign attribute to delete this item"
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                          {
                            key: "no-data",
                            fn: function () {
                              return [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-4",
                                    attrs: {
                                      type: "warning",
                                      dense: "",
                                      border: "left",
                                    },
                                  },
                                  [_vm._v(" Sorry, no data to display ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "no-results",
                            fn: function () {
                              return [
                                _c(
                                  "v-alert",
                                  {
                                    staticClass: "mt-4",
                                    attrs: {
                                      type: "warning",
                                      dense: "",
                                      border: "left",
                                    },
                                  },
                                  [_vm._v(" Sorry, no results found ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _c(
                        "v-overlay",
                        {
                          staticClass: "align-center justify-center",
                          attrs: { value: _vm.overlay },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", size: "64" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            fullscreen: "",
                            scrollable: "",
                            transition: "dialog-bottom-transition",
                          },
                          model: {
                            value: _vm.editDialog,
                            callback: function ($$v) {
                              _vm.editDialog = $$v
                            },
                            expression: "editDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "mb-5 flex-grow-0",
                                  attrs: { dark: "", color: "primary" },
                                },
                                [
                                  _c("v-toolbar-title", [
                                    _vm._v("Object Relation"),
                                  ]),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", dark: "" },
                                      on: { click: _vm.closeEditDialog },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "aria-label": "Close",
                                            "aria-hidden": "false",
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                { staticClass: "pt-3 pb-0" },
                                [
                                  _c(
                                    "v-form",
                                    {
                                      ref: "objectRelationEditForm",
                                      model: {
                                        value: _vm.editItemIsValid,
                                        callback: function ($$v) {
                                          _vm.editItemIsValid = $$v
                                        },
                                        expression: "editItemIsValid",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { md: "12", xs: "12" } },
                                            [
                                              !_vm.inEditMode
                                                ? _c(
                                                    "v-alert",
                                                    {
                                                      staticClass: "mb-6",
                                                      attrs: {
                                                        border: "top",
                                                        color: "blue",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "Please select a Relation Type."
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c("v-autocomplete", {
                                                attrs: {
                                                  label: "Relation Type",
                                                  items:
                                                    _vm.lookupData
                                                      .relationTypes,
                                                  disabled: _vm.inEditMode,
                                                  "item-text": "text",
                                                  "item-value": "id",
                                                  rules: [
                                                    (v) =>
                                                      !!v ||
                                                      "Select a Relation Type",
                                                  ],
                                                  placeholder:
                                                    "Select a Relation Type",
                                                  required: "",
                                                  outlined: "",
                                                  dense: "",
                                                  clearable: "",
                                                },
                                                on: {
                                                  change:
                                                    _vm.loadPrimaryForeignLookup,
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "label",
                                                    fn: function () {
                                                      return [
                                                        _vm._v(
                                                          " Relation Type "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "red--text",
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.editedItem
                                                      .relationTypeId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.editedItem,
                                                      "relationTypeId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "editedItem.relationTypeId",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.hasRelationType && !_vm.inEditMode
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { md: "12", xs: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        border: "top",
                                                        color: "blue",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        "Please select the Primary and Foreign Objects."
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isDQRI
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { md: "6", xs: "6" } },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.inEditMode,
                                                        row: "",
                                                        hidden: "",
                                                        hint: "Please select BDL or UDL Primary Object",
                                                        "persistent-hint": "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.loadPrimaryForeignLookup,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem
                                                            .primaryObjectType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            "primaryObjectType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem.primaryObjectType",
                                                      },
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "BDL",
                                                          value: "TOB",
                                                        },
                                                      }),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "UDL",
                                                          value: "SOB",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { md: "6", xs: "6" } },
                                                [
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.inEditMode,
                                                        row: "",
                                                        hint: "Please select BDL or UDL Foreign Object",
                                                        "persistent-hint": "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.loadPrimaryForeignLookup,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem
                                                            .foreignObjectType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            "foreignObjectType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem.foreignObjectType",
                                                      },
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "BDL",
                                                          value: "TOB",
                                                        },
                                                      }),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "UDL",
                                                          value: "SOB",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.hasRelationType
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { md: "6", xs: "6" } },
                                                [
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm.lookupData
                                                          .primaryTargetObjects,
                                                      disabled: _vm.inEditMode,
                                                      "append-outer-icon":
                                                        "mdi-arrow-left",
                                                      label:
                                                        "Primary Target Object",
                                                      "item-text": "text",
                                                      "item-value": "id",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Select a Primary Target Object",
                                                      ],
                                                      placeholder:
                                                        "Select a primary target object",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.setObjectRelationAttributes,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                " Primary Object "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "red--text",
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2214638847
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.editedItem
                                                          .primaryTargetObjectId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "primaryTargetObjectId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.primaryTargetObjectId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-col",
                                                { attrs: { md: "6", xs: "6" } },
                                                [
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      items:
                                                        _vm.lookupData
                                                          .foreignTargetObjects,
                                                      disabled: _vm.inEditMode,
                                                      "prepend-icon":
                                                        "mdi-arrow-right",
                                                      label:
                                                        "Foreign Target Object",
                                                      "item-text": "text",
                                                      "item-value": "id",
                                                      rules: [
                                                        (v) =>
                                                          !!v ||
                                                          "Select a Foreign Target Object",
                                                      ],
                                                      placeholder:
                                                        "Select a foreign target object",
                                                      required: "",
                                                      outlined: "",
                                                      dense: "",
                                                      clearable: "",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.setObjectRelationAttributes,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _vm._v(
                                                                " Foreign Object "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "red--text",
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2750680301
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.editedItem
                                                          .foreignTargetObjectId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.editedItem,
                                                          "foreignTargetObjectId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "editedItem.foreignTargetObjectId",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.hasRelationType &&
                                      _vm.showAltKeySelection &&
                                      !_vm.isExisting
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  attrs: { md: "12", xs: "12" },
                                                },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      staticClass: "mb-6",
                                                      attrs: {
                                                        border: "top",
                                                        color: "blue",
                                                        dark: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        'Please select to use either the Primary or Alternate key for the "Primary Object"'
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-radio-group",
                                                    {
                                                      attrs: {
                                                        rules: [
                                                          (v) =>
                                                            v != null ||
                                                            "Please select Primary or Alternate Key",
                                                        ],
                                                        mandatory: "",
                                                      },
                                                      on: {
                                                        change:
                                                          _vm.setObjectRelationAttributes,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editedItem
                                                            .useAlternateKey,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.editedItem,
                                                            "useAlternateKey",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "editedItem.useAlternateKey",
                                                      },
                                                    },
                                                    [
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label: "Primary Key",
                                                          value: false,
                                                        },
                                                      }),
                                                      _c("v-radio", {
                                                        attrs: {
                                                          label:
                                                            "Alternate Key",
                                                          value: true,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.hasObjectsSelected &&
                                      _vm.invalidAttributeCount &&
                                      !_vm.isExisting
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "mb-6",
                                              attrs: {
                                                border: "bottom",
                                                color: "red darken-2",
                                                dark: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "mdi-alert-octagon-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "There is not enough primary/alternate keys to foreign keys, please change your selection."
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.inEditMode && _vm.isExisting
                                        ? _c(
                                            "v-alert",
                                            {
                                              staticClass: "mb-6",
                                              attrs: {
                                                border: "bottom",
                                                color: "red darken-2",
                                                dark: "",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    "mdi-alert-octagon-outline"
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                "These objects have an existing relationship for this relation type. Only one relationship is allowed per relation type."
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showAttributeSelection
                                        ? _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                { staticClass: "pb-0" },
                                                [
                                                  _c(
                                                    "v-list",
                                                    { attrs: { dense: "" } },
                                                    _vm._l(
                                                      _vm.objectRelationAttributes,
                                                      function (item, i) {
                                                        return _c(
                                                          "v-list-item",
                                                          { key: i },
                                                          [
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "object-relation-attribute-row",
                                                                  {
                                                                    attrs: {
                                                                      item: item,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-overlay",
                                                {
                                                  staticClass:
                                                    "align-center justify-center",
                                                  attrs: {
                                                    value: _vm.attrOverlay,
                                                  },
                                                },
                                                [
                                                  _c("v-progress-circular", {
                                                    attrs: {
                                                      indeterminate: "",
                                                      size: "64",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      !_vm.editItemIsValid
                                        ? _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "12" } },
                                                [
                                                  _c(
                                                    "v-alert",
                                                    {
                                                      attrs: {
                                                        color: "pink darken-1",
                                                        outlined: "",
                                                        dense: "",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "All required fields must be completed to enable the 'Save' button."
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    outlined: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click: _vm.closeEditDialog,
                                                  },
                                                },
                                                [_vm._v("Cancel")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    disabled:
                                                      !_vm.editItemIsValid ||
                                                      !_vm.showAttributeSelection,
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.save },
                                                },
                                                [_vm._v("Save")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            width: "500",
                            transition: "dialog-bottom-transition",
                          },
                          model: {
                            value: _vm.deleteDialog,
                            callback: function ($$v) {
                              _vm.deleteDialog = $$v
                            },
                            expression: "deleteDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "mb-4",
                                  attrs: { dark: "", color: "primary" },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    [
                                      _c("v-icon", [_vm._v("mdi-warning")]),
                                      _vm._v("Delete"),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", dark: "" },
                                      on: { click: _vm.closeDeleteDialog },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "aria-label": "Close",
                                            "aria-hidden": "false",
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "font-weight-bold text-md-body-1",
                                },
                                [_vm._v("Are you sure you want to delete?")]
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    outlined: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.closeDeleteDialog,
                                                  },
                                                },
                                                [_vm._v("No")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.deleteItemConfirm,
                                                  },
                                                },
                                                [_vm._v("Yes")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            width: "500",
                            transition: "dialog-bottom-transition",
                          },
                          model: {
                            value: _vm.deleteCertifyDialog,
                            callback: function ($$v) {
                              _vm.deleteCertifyDialog = $$v
                            },
                            expression: "deleteCertifyDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "mb-4",
                                  attrs: { dark: "", color: "primary" },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    [
                                      _c("v-icon", [_vm._v("mdi-warning")]),
                                      _vm._v("Delete"),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", dark: "" },
                                      on: { click: _vm.closeCertifyDialog },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "aria-label": "Close",
                                            "aria-hidden": "false",
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "font-weight-bold text-md-body-1",
                                },
                                [
                                  _vm._v(
                                    "Are you sure you want to Certify/Decertify relations?"
                                  ),
                                ]
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    outlined: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.closeCertifyDialog,
                                                  },
                                                },
                                                [_vm._v("No")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.saveRelationshipChanges,
                                                  },
                                                },
                                                [_vm._v("Yes")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-dialog",
                        {
                          attrs: { width: "500" },
                          model: {
                            value: _vm.dqRiConfirmDialog,
                            callback: function ($$v) {
                              _vm.dqRiConfirmDialog = $$v
                            },
                            expression: "dqRiConfirmDialog",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-toolbar",
                                {
                                  staticClass: "mb-4",
                                  attrs: { dark: "", color: "primary" },
                                },
                                [
                                  _c(
                                    "v-toolbar-title",
                                    [
                                      _c("v-icon", [_vm._v("mdi-warning")]),
                                      _vm._v("Confirm"),
                                    ],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { icon: "", dark: "" },
                                      on: { click: _vm.cancelDqRi },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "aria-label": "Close",
                                            "aria-hidden": "false",
                                          },
                                        },
                                        [_vm._v("mdi-close")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "font-weight-bold text-md-body-1",
                                },
                                [
                                  _vm._v(
                                    "Please confirm that when saving you want to create a DQ RI record in the database for this object relationship."
                                  ),
                                ]
                              ),
                              _c(
                                "v-card-actions",
                                [
                                  _c(
                                    "v-container",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    outlined: "",
                                                    color: "secondary",
                                                  },
                                                  on: { click: _vm.cancelDqRi },
                                                },
                                                [_vm._v("No")]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass: "text-uppercase",
                                                  attrs: {
                                                    block: "",
                                                    tile: "",
                                                    color: "secondary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.dqRiConfirmDialog = false
                                                    },
                                                  },
                                                },
                                                [_vm._v("Yes")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }